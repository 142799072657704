import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable, tap } from 'rxjs';

import { PrivaContentHeaderModule } from '@priva/components/content-header';
import { PrivaContentSectionModule } from '@priva/components/content-section';
import { PrivaQueryableDataSource } from '@priva/components/filter';
import { PrivaLoaderModule } from '@priva/components/loader';
import { PrivaSpinnerModule } from '@priva/components/spinner';
import { PrivaTabListModule } from '@priva/components/tab-list';
import { CropState, ExpandedCrop } from '@priva/masterdata';

import { FeatureFlagsStore } from '@app/feature-flags';

import { CropsEmptyComponent } from '../crops-empty/crops-empty.component';
import { CropsFilterComponent } from '../crops-filter/crops-filter.component';
import { CropsTableComponent } from '../crops-table/crops-table.component';

@Component({
    selector: 'app-crops-management',
    templateUrl: './crops-management.component.html',
    styleUrls: ['./crops-management.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        PrivaContentHeaderModule,
        PrivaContentSectionModule,
        CropsFilterComponent,
        CropsTableComponent,
        CropsEmptyComponent,
        PrivaLoaderModule,
        PrivaSpinnerModule,
        AsyncPipe,
        TranslateModule,
        PrivaTabListModule,
    ],
})
export class CropsManagementComponent {
    public readonly featureFlagsStore = inject(FeatureFlagsStore);

    public dataSource: PrivaQueryableDataSource<ExpandedCrop>;
    public count$: Observable<number>;
    public results$: Observable<ExpandedCrop[]>;
    public currentCrops$: Observable<ExpandedCrop[]>;
    public upcomingCrops$: Observable<ExpandedCrop[]>;
    public completedCrops$: Observable<ExpandedCrop[]>;

    public loaded = false;

    public onDataSourceReady(dataSource: PrivaQueryableDataSource<ExpandedCrop>) {
        this.loaded = false;

        this.dataSource = dataSource;
        this.results$ = dataSource.results$;
        this.count$ = dataSource.count$?.pipe(tap(() => (this.loaded = true)));

        // Create the separate crops streams
        this.currentCrops$ = this.results$.pipe(
            map((crops) => crops.filter((crop) => crop.state === CropState.Current)),
        );
        this.upcomingCrops$ = this.results$.pipe(
            map((crops) => crops.filter((crop) => crop.state === CropState.Upcoming)),
        );
        this.completedCrops$ = this.results$.pipe(
            map((crops) => crops.filter((crop) => crop.state === CropState.Completed)),
        );
    }
}
